export default {
  name: "ExperienceComponent",
  data() {
    return {
      jobs: [
        {
          title: "Senior Software Engineer",
          company: "Netlight Consulting GmbH, Munich, Germany",
          logo: "netlight_logo.svg",
          website: "https://www.netlight.com",
          linkedin: "https://www.linkedin.com/company/netlight-consulting/",
          duration: "March 2021 - Present",
          responsibilities: [
            "Led development of high-performance, scalable systems using microservices architecture and CI/CD pipelines.",
            "Implemented API integrations, optimized application performance, and mentored junior developers.",
            "Collaborated with stakeholders to define and prioritize features.",
            "Created custom integrations between Salesforce and other enterprise systems.",
            "Implemented a version control system for cloud infrastructure management."
          ],
        },
        {
          title: "Backend Engineer",
          company: "Remberg GmbH, Munich, Germany",
          logo: "remberg_logo.svg",
          website: "https://www.remberg.io",
          linkedin: "https://www.linkedin.com/company/remberg/",
          duration: "October 2020 - February 2021",
          responsibilities: [
            "Developed and enhanced Asset Relationship Management System for industrial machines.",
            "Implemented service management features, including work order scheduling and maintenance tracking.",
            "Integrated frontend libraries in Angular to create an efficient scheduling calendar for client service management."
          ],
        },
        {
          title: "Fullstack Developer",
          company: "Mecuris GmbH, Munich, Germany",
          logo: "mecuris_logo.jpg",
          website: "https://www.mecuris.com",
          linkedin: "https://www.linkedin.com/company/mecuris/",
          duration: "August 2019 - September 2020",
          responsibilities: [
            "Built a responsive, high-performance full-stack web application with efficient data fetching using GraphQL and Apollo.",
            "Implemented CI/CD pipeline, reducing deployment time significantly.",
            "Developed REST APIs for seamless integration with client systems.",
            "Created a responsive design for cross-device compatibility."
          ],
        },
        {
          title: "Praktikum Software Developer",
          company: "crossvertise GmbH, Munich, Germany",
          logo: "crossvertise_logo.svg",
          website: "https://www.crossvertise.com",
          linkedin: "https://www.linkedin.com/company/crossvertise-gmbh/",
          duration: "December 2018 - July 2019",
          responsibilities: [
            "Migrated web application from Knockout.js to Angular, improving performance and user experience.",
            "Enhanced search functionality with Elasticsearch for optimal advertisement booking results.",
            "Developed a live monitoring system for fault tracking and real-time notifications.",
            "Collaborated with stakeholders to align designs with business goals using Figma."
          ],
        },
        {
          title: "Associate Developer",
          company: "FlexiEle, Gurugram, India",
          logo: "flexiele_logo.png",
          website: "https://www.flexiele.com",
          linkedin: "https://www.linkedin.com/company/flexiele-consulting-services-pvt-ltd/",
          duration: "December 2017 - November 2018",
          responsibilities: [
            "Redesigned backend architecture and implemented new features in HRMS platform using Zend framework (PHP) and MySQL.",
            "Transitioned to Node.js and Angular to improve scalability and efficiency.",
            "Created performance management and customer support tools.",
            "Enhanced system stability using asynchronous features in Node.js.",
            "Addressed customer requirements and evolved the platform with new features."
          ],
        },
      ],
    };
  },
  methods: {
    getLogoPath(logo) {
      return require(`@/assets/${logo}`);
    }
  }
};
