export default {
  name: 'SkillsComponent',
  data() {
    return {
      skillCategories: [
        {
          name: "Programming Languages",
          skills: ["Java", "Python", "JavaScript", "TypeScript"]
        },
        {
          name: "Frameworks & Libraries",
          skills: ["Spring Boot", "React", "Vue.js", "Node.js"]
        },
        // Add more skill categories
      ]
    }
  }
}