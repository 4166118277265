<div id="app">
  <nav>
    <ul>
      <li><a href="#home" @click="currentView = 'home'">Home</a></li>
      <li><a href="#experience" @click="currentView = 'experience'">Experience</a></li>
      <li><a href="#skills" @click="currentView = 'skills'">Skills</a></li>
      <li><a href="#projects" @click="currentView = 'projects'">Projects</a></li>
      <li><a href="#contact" @click="currentView = 'contact'">Contact</a></li>
    </ul>
  </nav>
  <main>
    <Home v-if="currentView === 'home'" />
    <Experience v-if="currentView === 'experience'" />
    <Skills v-if="currentView === 'skills'" />
    <Projects v-if="currentView === 'projects'" />
    <Contact v-if="currentView === 'contact'" />
  </main>
  <footer>
    <p>© 2024 Chirag Bansal. All rights reserved.</p>
  </footer>
</div>