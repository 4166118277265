export default {
  name: 'ProjectsComponent',
  data() {
    return {
      projects: [
        {
          name: "E-commerce Microservices Architecture",
          description: "Designed and implemented a scalable microservices architecture for Walmart's e-commerce platform",
          technologies: ["Java", "Spring Boot", "Kafka", "MongoDB", "Docker"]
        },
        // Add more projects
      ]
    }
  }
}