import Home from '../Home/HomeComponent.vue'
import Experience from '../ExperienceComponent/ExperienceComponent.vue'
import Skills from '../Skills/SkillsComponent.vue'
import Projects from '../Projects/ProjectsComponent.vue'
import Contact from '../Contact/ContactComponent.vue'

export default {
  name: 'App',
  components: {
    Home,
    Experience,
    Skills,
    Projects,
    Contact
  },
  data() {
    return {
      currentView: 'home'
    }
  }
}