<div class="home">
  <div class="hero">
    <div class="hero-about">
      <img src="@/assets/1704436678885.jpeg" alt="Chirag Bansal" class="profile-image">
      <h1>Chirag Bansal</h1>
      <p>Senior Software Engineer</p>
    </div>
    <div class="hero-content">
      <p class="hero-description">Hello! I'm Chirag Bansal, a passionate Senior Software Engineer with a knack for solving complex problems and creating efficient, scalable solutions. With expertise in various programming languages and frameworks, I strive to deliver high-quality software that makes a difference.</p>
      <button class="cta-button" @click="scrollToProjects">View My Work</button>
    </div>
    <div class="hero-image">
      <div class="code-block">
        <pre><code>
function innovate() {
  while (true) {
    learn();
    create();
    improve();
  }
}

innovate();
        </code></pre>
      </div>
    </div>
  </div>
  
  <div class="skills-overview">
    <h2>Tech Stack</h2>
    <div class="skill-carousel">
      <div class="skill-category">
        <h4>Languages</h4>
        <div class="skill-icons">
          <i class="devicon-java-plain colored" title="Java"></i>
          <i class="devicon-typescript-plain colored" title="TypeScript"></i>
          <i class="devicon-csharp-plain" title="C#"></i>
          <i class="devicon-python-plain colored" title="Python"></i>
          <i class="devicon-php-plain" title="PHP"></i>
        </div>
      </div>
      <div class="skill-category">
        <h4>Frameworks & Libraries</h4>
        <div class="skill-icons">
          <i class="devicon-spring-plain colored" title="Spring Boot"></i>
          <i class="devicon-vuejs-plain colored" title="Vue.js"></i>
          <i class="devicon-nodejs-plain colored" title="Node.js"></i>
          <i class="devicon-express-original colored" title="Express.js"></i>
          <i class="devicon-angularjs-plain colored" title="Angular"></i>
          <i class="devicon-django-plain" title="Django"></i>
          <i class="devicon-dotnetcore-plain"></i>
        </div>
      </div>
      <div class="skill-category">
        <h4>Databases</h4>
        <div class="skill-icons">
          <i class="devicon-mongodb-plain colored" title="MongoDB"></i>
          <i class="devicon-mysql-plain colored" title="MySQL"></i>
        </div>
      </div>
      <div class="skill-category">
        <h4>Tools & Platforms</h4>
        <div class="skill-icons">
          <i class="devicon-git-plain colored" title="Git"></i>
          <i class="devicon-docker-plain colored" title="Docker"></i>
          <i class="devicon-amazonwebservices-original colored" title="Amazon Web Services"></i>
          <i class="devicon-salesforce-plain colored" title="Salesforce"></i>
        </div>
      </div>
    </div>
  </div>
</div>
