<div class="experience-timeline">
  <h2>Professional Experience</h2>
  <div class="timeline">
    <div v-for="(job, index) in jobs" :key="index" class="timeline-item">
      <div class="timeline-date">
        <span>{{ job.duration }}</span>
      </div>
      <div class="timeline-content">
        <div class="card">
          <h3>{{ job.title }} at {{ job.company }}</h3>
          <div class="company-logos">
            <a :href="job.website" target="_blank">
              <img :src="getLogoPath(job.logo)" :alt="`${job.company} logo`" class="company-logo">
            </a>
            <a :href="job.linkedin" target="_blank">
              <img src="@/assets/linkedin_logo.png" alt="LinkedIn logo" class="company-logo">
            </a>
          </div>
          <ul>
            <li v-for="(responsibility, i) in job.responsibilities" :key="i">
              {{ responsibility }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>